<template>
  <div>
    <div class="row" v-if="showHeading">
      <div class="col-md-12">
        <div class="Datastream-main-rect">
          <b-row class="">
            <b-col
              ><h5 class="Datastream-title">
                {{ headingText }}
              </h5></b-col
            ></b-row
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" style="max-width: 100% !important">
        <b-table
          class="tablestyle m-0"
          :class="{ customRow: customRowColor }"
          :items="tableProperties.items"
          :fields="tableProperties.fields"
          head-variant="#6192b1"
          striped
          :busy="tableLoading"
          empty-text="No Records Found"
          :no-local-sorting="true"
          :selectable="enable || false"
          :select-mode="mode"
          @sort-changed="sortingChanged"
          responsive
        >
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
          <template #head()="data">
            <div class="column-heading">{{ data.label }}</div>
          </template>
          <template v-slot:cell(verification)="row">
            <span :class="getStatusColor(row.item.verify)"></span>
            <span v-if="row.item.verify">&nbsp;Success</span>
            <span v-else>&nbsp;Pending</span>
          </template>
          <template v-slot:cell(rowDetails)="row">
            <span class="ml-1">
              <span
                @click="row.toggleDetails"
                style="cursor: pointer"
                v-if="row.item.platFormGenereByAdType !== 'Total'"
              >
                <i
                  class="far fa-minus-square"
                  v-if="row.detailsShowing"
                  style="color: red"
                ></i>
                <i
                  class="far fa-plus-square"
                  v-else
                  style="background-color: #2188ff"
                ></i>
                <span> {{ row.item.platFormGenereByAdType }}</span>
              </span>
              <span v-else>{{ row.item.platFormGenereByAdType }}</span>
            </span>
          </template>
          <template v-slot:cell(channelDetails)="row">
            <span class="ml-1">
              <span
                @click="row.toggleDetails"
                style="cursor: pointer"
                v-if="row.item.channelName !== 'Total'"
              >
                <i
                  class="far fa-minus-square"
                  v-if="row.detailsShowing"
                  style="color: red"
                ></i>
                <i
                  class="far fa-plus-square"
                  v-else
                  style="background-color: #2188ff"
                ></i>
                <span> {{ row.item.channelName }}</span>
              </span>
              <span v-else>{{ row.item.channelName }}</span>
            </span>
          </template>
          <template v-slot:row-details="row">
            <div style="width: 100%" class="mb-4 mt-1">
              <div class="collapse-inline-table">
                <table class="table" style="border: 1px solid">
                  <thead style="background-color: #fff">
                    <th
                      class="thClassLeft"
                      v-for="(tableHead, index) in row.fields"
                      :key="index"
                    >
                      {{ tableHead.label }}
                    </th>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in row.item.arrayOfResponses" :key="index">
                      <td style="text-align: left">
                        {{ data.platFormGenereByAdType }}
                      </td>
                      <td style="text-align: left">{{ data.website }}</td>
                      <td style="text-align: left">
                        {{ data.adType }}
                      </td>
                      <td style="text-align: left">
                        {{ data.impressions }}
                      </td>
                      <td style="text-align: left">
                        {{ data.clicks }}
                      </td>
                      <td style="text-align: left" v-if="data.videoViews">
                        {{ data.videoViews }}
                      </td>
                      <td style="text-align: left" v-if="data.conversions">
                        {{ data.conversions }}
                      </td>
                      <td style="text-align: left" v-if="data.cpa">
                        {{ data.cpa }}
                      </td>
                      <td style="text-align: left" v-if="data.engagements">
                        {{ data.engagements }}
                      </td>
                      <td style="text-align: left" v-if="data.reach">
                        {{ data.reach }}
                      </td>
                      <td style="text-align: left" v-if="data.cpm">
                        {{ data.cpm }}
                      </td>
                      <td style="text-align: left">{{ data.cost }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
          <template v-slot:cell(Transaction)="row">
            <span>{{ orgnizeNumber(row.item.transactions) }}</span>
          </template>
          <template v-slot:cell(Revenue)="row">
            <span>{{ orgnizeNumber(row.item.revenue) }}</span>
          </template>
          <template v-slot:cell(Sessions)="row">
            <span>{{ orgnizeNumber(row.item.sessions) }}</span>
          </template>
          <template v-slot:cell(Impressions)="row">
            <span>{{ orgnizeNumber(row.item.impressions) }}</span>
          </template>
          <template v-slot:cell(Clicks)="row">
            <span>{{ orgnizeNumber(row.item.clicks) }}</span>
          </template>
          <template v-slot:cell(VideoView)="row">
            <span>{{ orgnizeNumber(row.item.video_views) }}</span>
          </template>
          <template v-slot:cell(Conversions)="row">
            <span>{{ orgnizeNumber(row.item.conversions) }}</span>
          </template>
          <template v-slot:cell(Cost)="row">
            <span>{{ orgnizeNumber(row.item.cost) }}</span>
          </template>
          <template v-slot:cell(ESDashboardValue)="row">
            <span>{{ orgnizeNumber(row.item.esValue) }}</span>
          </template>
          <template v-slot:cell(MongoDashboardValue)="row">
            <span>{{ orgnizeNumber(row.item.mongoValue) }}</span>
          </template>
          <template v-slot:cell(Difference)="row">
            <span>{{ orgnizeNumber(row.item.difference) }}</span>
          </template>
          <template v-slot:cell(ROIRange)="row">
            <span>{{ row.item.kpi.roiRange }}Months</span>
          </template>

          <template v-slot:cell(status)="row">
            <span :class="getStatusColor(row.item.status)"></span>
            <span v-if="row.item.status">&nbsp;Active</span>
            <span v-else>&nbsp;InActive</span>
          </template>
          <template v-slot:cell(Status)="row">
            <span :class="getStatusColor(row.item.status)"></span>
            <span v-if="row.item.Status === 'Finished'">&nbsp;Finished</span>
            <span v-else-if="row.item.Status === 'Running'">&nbsp;Running</span>
            <span v-else>Failed</span>
          </template>
          <template v-slot:cell(AiStatusOptimization)="row">
            <span v-if="row.item.optimization.status === 'ended'">Success</span>
            <span v-else-if="row.item.optimization.status === 'error'">Unsuccessful</span>
            <span v-else-if="row.item.optimization.status === 'notStarted'">Pending</span>
            <span v-else-if="row.item.optimization.status === 'started'">Running</span>
          </template>

          <template v-slot:cell(AiStatusAttribution)="row">
            <span v-if="row.item.attribution.status === 'ended'">Success</span>
            <span v-else-if="row.item.attribution.status === 'error'">Unsuccessful</span>
            <span v-else-if="row.item.attribution.status === 'notStarted'">Pending</span>
            <span v-else-if="row.item.attribution.status === 'started'">Running</span>
          </template>
          <template v-slot:cell(Reauthorize)="row">
            <span
              style="
                background-color: #0276fe;
                border-radius: 3px;
                cursor: pointer;
                font-size: 16px;
                color: #fff;
              "
              class="px-3 py-2"
              @click="$emit('reauthorize', row.item)"
              >Reauthorize</span
            >
          </template>
          <template v-slot:cell(AiStatuspreProcess)="row">
            <span v-if="row.item.preProcess.status === 'ended'">Success</span>
            <span v-else-if="row.item.preProcess.status === 'error'">Unsuccessful</span>
            <span v-else-if="row.item.preProcess.status === 'notStarted'">Pending</span>
            <span v-else-if="row.item.preProcess.status === 'started'">Running</span>
          </template>
          <template v-slot:cell(actionRow)="row">
            <span
              v-if="
                row.item.preProcess.status === 'error' ||
                row.item.attribution.status === 'error'
              "
              style="
                background-color: #ff5a5a;
                border-radius: 3px;
                cursor: pointer;
                font-size: 16px;
                color: #fff;
              "
              class="px-3 py-2"
              @click.once="retryModel(row.item)"
              >Retry</span
            >
            <span
              v-else-if="
                row.item.preProcess.status === 'started' ||
                row.item.attribution.status === 'started' ||
                row.item.optimization.status === 'started'
              "
              >Running</span
            >
            <span
              v-else-if="
                row.item.preProcess.status === 'notStarted' &&
                row.item.attribution.status === 'notStarted' &&
                row.item.optimization.status === 'notStarted'
              "
              >Not Started</span
            >
            <div
              v-else-if="
                row.item.preProcess.status === 'ended' &&
                row.item.attribution.status === 'ended'
              "
            >
              <span
                v-if="row.item.optimization.status === 'error'"
                style="
                  background-color: #ff5a5a;
                  border-radius: 3px;
                  cursor: pointer;
                  font-size: 16px;
                  color: #fff;
                "
                class="px-3 py-2 mr-2"
                @click.once="retryModel(row.item)"
                >Retry</span
              >
              <span
                style="
                  background-color: #0276fe;
                  border-radius: 3px;
                  cursor: pointer;
                  font-size: 16px;
                  color: #fff;
                "
                @click="viewResult(row.item)"
                class="px-3 py-2"
                >View Result</span
              >
            </div>
            <span v-else>Pending</span>
          </template>

          <template v-slot:cell(actionRowUL)="row">
            <span
              v-if="row.item.Status === 'error'"
              style="
                background-color: #ff5a5a;
                border-radius: 3px;
                cursor: pointer;
                font-size: 16px;
                color: #fff;
              "
              class="px-3 py-2"
              @click.once="retryModel(row.item)"
              >Retry</span
            >
            <span v-else-if="row.item.Status === 'Started'">Running</span>
            <span v-else-if="row.item.Status === 'notStarted'">Not Started</span>
            <div v-else-if="row.item.Status === 'Failed'">
              <span
                v-if="row.item.Status === 'Failed'"
                style="
                  background-color: #ff5a5a;
                  border-radius: 3px;
                  cursor: pointer;
                  font-size: 16px;
                  color: #fff;
                "
                class="px-3 py-2 mr-2"
                @click.once="retryModel(row.item)"
                >Retry</span
              >
              <!-- <span
                style="background-color: #0276fe;border-radius: 3px;cursor: pointer;font-size: 16px;color: #fff;"
                @click="viewResult(row.item)"
                class="px-3 py-2"
                >View Result</span
              > -->
            </div>
            <span v-else-if="row.item.Status === 'Finished'">
              <span
                style="
                  background-color: #0276fe;
                  border-radius: 3px;
                  cursor: pointer;
                  font-size: 16px;
                  color: #fff;
                "
                @click="viewResult(row.item)"
                class="px-3 py-2"
                >View Result</span
              >
            </span>
            <span v-else>Pending</span>
          </template>
          <template v-slot:cell(switchUser)="row">
            <span
              v-if="parentEmail === row.item.email"
              style="
                background-color: #0276fe;
                border-radius: 3px;
                cursor: pointer;
                font-size: 16px;
                color: #fff;
              "
              class="px-3 py-2"
              @click="$emit('switchUser', row.item)"
              >Default</span
            >
            <span
              v-else
              style="
                background-color: #0276fe;
                border-radius: 3px;
                cursor: pointer;
                font-size: 16px;
                color: #fff;
              "
              class="px-3 py-2"
              @click="$emit('switchUser', row.item)"
              >Switch</span
            >
          </template>
          <template v-slot:cell(dateFormatter)="row">
            <span>{{ dateformatter(row.item.kpi.date) }}</span>
          </template>
          <template v-slot:cell(chnageBoolean)="row">
            <span v-if="row.item['DATA CONTINUOUS']"
              ><i style="color: green" class="fa fa-check-square" aria-hidden="true"></i
            ></span>
            <span v-else><i style="color: red" class="fas fa-times"></i></span>
          </template>
          <template v-slot:cell(chnageBoolean1)="row">
            <div v-if="row.item['DATA SUFFICIENT'] === 'loader'">
              <div class="Budgetloader"></div>
            </div>
            <div v-else>
              <span v-if="row.item['DATA SUFFICIENT']"
                ><i style="color: green" class="fa fa-check-square" aria-hidden="true"></i
              ></span>
              <span v-else><i style="color: red" class="fas fa-times"></i></span>
            </div>
          </template>
          <template v-slot:cell(assignedBrands)="row">
            <span class="inProgress"
              >&nbsp; {{ row.item.brands.length }} Brand
              <span
                class="inProgresstext"
                style="text-align: start !important"
                v-if="row.item.brands.length !== 0"
              >
                <span v-for="(access, index) in row.item.brands" :key="index">
                  {{ index + 1 }}. {{ access }} <br /></span
              ></span>
            </span>
          </template>
          <template v-slot:cell(action)="row">
            <div>
              <span
                @click="$emit('editRow', row.item)"
                style="cursor: pointer"
                title="Edit"
                ><i class="fas fa-edit" style="color: blue"></i
              ></span>
              <span
                v-if="showDelete"
                style="cursor: pointer"
                title="Delete"
                class="ml-2"
                @click="$emit('deleteUser', row.item)"
                ><i class="fas fa-trash-alt" style="color: red"></i
              ></span>
              <span
                style="cursor: pointer"
                title="Resend Email"
                class="ml-2"
                @click="$emit('resentEmail', row.item)"
                v-if="!row.item.verify"
                ><img
                  src="@/assets/Icons/resentEmail.png"
                  alt="resend"
                  width="20px"
                  height="20px"
              /></span>
            </div>
          </template>
          <template v-slot:cell(toolsAccess)="row">
            <span class="inProgress"
              >&nbsp; {{ row.item.subscriberToolType.length }} tools access
              <span
                class="inProgresstext"
                style="text-align: start !important"
                v-if="row.item.subscriberToolType.length !== 0"
              >
                <span v-for="(access, index) in row.item.subscriberToolType" :key="index">
                  {{ index + 1 }}. {{ access }} <br /></span
              ></span>
            </span>
          </template>
        </b-table>
      </div>
    </div>
    <div
      v-if="tableProperties.items === undefined || tableProperties.items.length === 0"
      class="no-items-wrapper"
    >
      <div class="text-center">
        <img src="@/assets/tech-life-media2.png" alt="" />
        <div class="no-items-description">
          {{ noDataText }}
        </div>
        <!--<div v-else-if="searchedTextValue !== ''" class="no-items-description">
          Oops!<br />
          Sorry to say, it seems like no plan has been found
        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { abbreviateNumber } from "js-abbreviation-number";
export default {
  data() {
    return {
      parentEmail: "",
    };
  },
  // props: ["tableProperties", "tableLoading", "enable", "mode","noDataText"],
  props: {
    tableProperties: {
      type: Object,
      default: () => {},
    },
    showHeading: {
      type: Boolean,
      default: false,
    },
    headingText: {
      type: String,
      default: "",
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
    enable: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "multi",
    },
    customRowColor: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: "No plans have been added yet in your shared space",
    },
  },
  created() {},
  computed: {
    orgnizeNumber() {
      return (data) => {
        if (data > 999) {
          return abbreviateNumber(data, 1);
        } else {
          return Math.round(data * 10) / 10;
        }
      };
    },
    dateformatter() {
      return (data) => {
        return moment(new Date(data)).format("YYYY-MM-DD");
      };
    },
    showDelete() {
      var id = sessionStorage.getItem("clientId");
      id = id.split("@")[1];
      if (id === "datapoem.com") {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.parentEmail = sessionStorage.getItem("parentEmail");
  },
  methods: {
    getStatusColor(status) {
      switch (status) {
        case true:
          return "dot dot-green";
        case false:
          return "dot dot-red";
        default:
          return "";
      }
    },
    retryModel(event) {
      this.$emit("retryModel", event);
    },
    sortingChanged(e) {
      this.$emit("sortField", e);
    },
    viewResult(event) {
      this.$emit("viewModelResult", event);
    },
  },
};
</script>
<style>
.column-heading {
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
}
.b-table-details td:empty {
  display: none;
}
.b-table-has-details {
  border-bottom: 1px solid;
}
.rowStyling {
  cursor: pointer;
  color: blue;
}
.rowStyling:hover {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  opacity: 0.8;
}
.thClass {
  background-color: #c53939;
  color: red;
  text-align: center !important;
}
.thClassCenterOption {
  width: 120px;
  background-color: #fff;
  border-top: 0 !important;
  color: #313846;
  text-align: center !important;
}
.thClassCenter {
  background-color: #fff;
  color: #313846;
  text-align: center !important;
  border-top: 0 !important;
}
.thClassLeft {
  background-color: #fff;
  color: #313846;
  text-align: left !important;
  border-top: 0 !important;
  font-family: ProximaNovaBold;
  font-size: medium;
}
.thClassRight {
  background-color: #fff;
  color: #313846;
  text-align: right !important;
  border-top: 0 !important;
}
.table td {
  font-family: ProximaNovaRegular;
  padding: 0.85rem !important;
  border-top: 0 !important;
}
.table-success,
.table-success > th,
.table-success > td {
  background-color: #182c25;
  font-weight: bold;
}
.table-success .thClassCenter {
  background-color: #182c25 !important;
}
.customRow .table-striped > tbody > tr:nth-child(odd) > td,
.customRow .table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #fff;
  box-shadow: 0 0 0px 0 #c9ced5;
}
.customRow .table-striped > tbody > tr:nth-child(even) > td,
.customRow .table-striped > tbody > tr:nth-child(even) > th {
  background-color: #fff;
  box-shadow: 0 0 0px 0 #c9ced5;
}
</style>
<style scoped>
.Datastream-title {
  font-family: ProximaNovaBold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
  position: relative;
  left: 20px;
  top: 20px;
}
.Datastream-main-rect {
  /* width: 1514px; */
  height: 126px;
  object-fit: contain;
  border-radius: 3px;
  box-shadow: 0 0 8px 0 #c9ced5;
  background-color: #ffffff;
  position: relative;
  top: 63px;
}
.no-items-wrapper {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-items-description {
  opacity: 0.5;
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
  margin-top: 42px;
}
.completed .completedtext,
.inProgress .inProgresstext {
  font-family: ProximaNovaRegular;
  visibility: hidden;
  background-color: #fff;
  color: #313846;
  box-shadow: 0 0 8px 0 #dadee4;
  text-align: center;
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;

  z-index: 1;
}
.completed:hover .completedtext {
  visibility: visible;
}
.inProgress:hover .inProgresstext {
  visibility: visible;
}
.tablestyle {
  font-size: 16px !important;
  font-family: ProximaNovaSemiBold;
  overflow-y: auto;
  width: 100%;
}
.tablestyle thead th {
  color: #313846;
  position: sticky;
  top: 0;
}
.edit-option-css {
  width: 23px;
}
table {
  color: #313846;
}
.table th {
  border-top: 0;
}
.edit {
  display: grid;
}
.vue-popover {
  width: 110px !important;
  background-color: #fff;
  color: #313846;
  box-shadow: none;
}
.btn {
  color: #313846;
  border-radius: 5px;
  border: 0px;
}
.btn:hover {
  opacity: 0.8;
}
.btn:focus {
  box-shadow: none;
  outline: none;
}
.successtext {
  display: grid;
}
hr {
  background-color: #fff;
  height: 1px;
}
.modal-fotter {
  justify-content: space-evenly;
  display: flex;
}
.progress {
  border-radius: 3px;
  height: 12px;
  margin-top: 7px;
}
</style>
